import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FroalaEditorModule as FroalaEditorModuleMain, FroalaViewModule} from 'angular-froala-wysiwyg';
import {FroalaEditorComponent} from "./froala-editor.component";

@NgModule({
  declarations: [
    FroalaEditorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FroalaEditorModuleMain.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  exports: [
    FroalaEditorComponent
  ]
})
export class FroalaEditorModule {
}
