import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {AuthService} from "../../../app/auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(
    private readonly popoverRef: PopoverRef,
    private readonly authService: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  public logout(): void {
    this.close();
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}
