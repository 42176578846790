<div class="vex-user-menu">
  <a (click)="close()"
     [routerLink]="['/apps/social/timeline']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:settings"></mat-icon>
    <div class="vex-user-menu-item__label">Account Settings</div>
  </a>

  <div class="border-b border-divider mx-4"></div>

  <a (click)="logout()"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="vex-user-menu-item__label">Sign Out</div>
  </a>
</div>
